.outer {
    display: flex;
    justify-content: center;
    color: #46579b;
    font-weight: 500;
    margin-top: 20px;
    font-size: 20px;
}

.background {
    background-color: #e2e5ef;
    padding: 20px;
}

.fontSizeTitle {
    font-size: 23px;
}

.nextEachOther2 {
    display: inline-block;
}

.margin {
    margin: 30px 30px 12px 40px;
}

.margin2 {
    margin: 10px;
}

.marginBottom {
    margin-bottom: 50px;
}

.center {
    justify-content: center;
}

.goToUploadBtn {
    cursor: pointer;
    background-color: #46579b;
    color: white;
    border: solid #46579b;
    border-radius: 10px;
}