.outer {
    display: inline-block;
}

.leftDiv {
    max-width: 200px;
    position: relative;
    display: inline-block;
    padding-bottom: 1.125em;
}

.rightDiv {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: absolute;
    left: 0;
    right: 0;
}

.textFont {
    margin-right: 5px;
}

.propertyFont {
    font-size: 16px;
    font-weight: 600;
}

.nextEachOther {
    display: flex;
}

.placing {
    width: 300px;
}