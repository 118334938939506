.aboveEachOther {
    display: flex;
    flex-direction: column;
}

.center {
    display: flex;
    justify-content: center;
}

.nextEachOther {
    display: flex;
}

.contentRight {
    display: flex;
    align-content: flex-end;
}

.spaceAround {
    display: flex;
    justify-content: space-between;
}

.moveRight {
    display: flex;
    align-self: flex-end;
}

.moveLeft {
    display: flex;
    align-self: flex-start;
}

.priceDiv {
    font-size: 15px;
    margin: 2px 3px 2px 3px;
    padding: 1px;
    border: solid grey 1px;
    border-radius: 2px;
}

.barrier {
    background-color: #1abbb9;
    height: 50px;
    width: 7px;
    border-radius: 3px;
    margin: 4px
}

.arrows {
    margin: 2px;
    padding: 1px;
    cursor: pointer;
    width: 30px;
}

.costIndex {
    background-color: #46579b;
    border-radius: 3px;
    color: white;
    padding: 5px 10px 5px 10px;
    margin: 5px;
    max-height: 35px;
}

.costIndexText {
    font-size: 17px;
    font-weight: 500;
    margin: 5px 5px 5px 30px;
    height: 35px;
    text-align: center;
}

.arrowRotate {
    transform: rotate(180deg);
}