.title {
    color: rgba(70, 87, 155, 1);
    font-size: 80px;
    font-weight: 500;
    text-align: center;
    margin: 0 0 40px 0;
}

.uploadInputSize {
    height: 38px;
}

.uploadButton {
    align-content: center;
}

.craftPlacing {
    min-width: 384px;
    width: 384px;
    padding-left: 10px;
    margin: 10px 10px 10px 0;
}

.csvFileUploadTitle {
    font-size: 30px;
    font-weight: 500;
    margin: 5px;
}

.csvUploadImg {
    margin: 5px;
}
.csvFilename {
    margin: 5px;
}