.div1 {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.divLeft {
    width: 50%;
}

.padding {
    padding: 10px;
}

.divRight {
    width: 50%;
}

.border {
    border: solid #46579b;
}

.borderFull {
    border-width: 2px;
}

.borderTop {
    border-width: 2px 0 0 0;
}

.borderLeftRightBottom {
    border-width: 0 2px 2px 2px;
}

.borderLeftBottom {
    border-width: 0 0 2px 2px;
}

.propertyValueFont {
    font-size: 16px;
    font-weight: 400;
}

.nextEachOther {
    display: flex;
}