body > #root > div {
    height: 100vh;
}

.mainContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    background-color: rgba(194, 219, 218, 1);
}

.color {
    background-color: #444444;
}

.fullWidth {
    width: 100%;
}

.container1 {
    width: 100%;
    min-height: 50vH;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchBar1 {
    width: 100%;
    height: 50px;
    max-width: 1000px;
    background: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 0 10px;
}

.searchInput1 {
    background: transparent;
    flex: 1;
    border: 0;
    outline: none;
    padding: 24px 20px;
    font-size: 20px;
    color: #cac7ff;
}

.container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.containerMargin {
    margin-top: 80px;
}

.lupe {
    padding-top: 4px;
    width: 21px;
    height: 21px;
}

.questionmark {
    padding-top: 4px;
    margin-left: 3px;
    width: 21px;
    height: 21px;
}

.questionmarkRight {
    padding-top: 10px;
    width: 15px;
    height: 15px;
}

.alignRight {
    margin-left: auto;
}

.lupeView {
    display: flex;
    align-items: center;
    margin: 0 0 2px 15px;
}

.searchBar {
    width: 100vh;
    height: 50px;
    display: flex;
    justify-content: flex-start;
}

.searchInput {
    background: rgba(255, 255, 255, 1);
    width: 100vh;
    height: 59px;
    display: flex;
    justify-content: center;
    border-radius: 22px;
    border: 2px solid #46579B;
    border-right: 0;
    align-items: center;
}

.onlySearchInput {
    background: transparent;
    width: 100vh;
    border: 0;
    padding: 3px 0 0 20px;
    font-size: 20px;
    outline: none;
}

.searchSubmit {
    display: flex;
    padding: 0 10px;
    align-items: center;

    background: #46579B;
    border-radius: 0 20px 20px 0;
    cursor: pointer;
    height: 59px;
    font-size: 20px;
    color: white;
    margin-left: auto;
}

.outerInnerSearch {
    display: flex;
    flex-direction: column;
    align-items: center;
}

::placeholder {
    color: #888888;
}

.search {
    width: 100%;
    text-align: center;
    margin: auto;
    alignment: center;
    height: 200px;
    font-size: 30px;
}

.moreSearchOptionsOuter {
    width: 100%;
}

.moreSearchOptionsButton {
    display: flex;
    margin: 10px 0 0 0;
    cursor: pointer;
}

.extraSearchPosText {
    height: 20px;
    border-width: 1px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    text-align: left;
    color: #46579B;
    font-size: 18px;
}

.extraSearchPosImg {
    margin: 3px 0 0 5px;
}

.advancedSearchTriangleImg {
    max-height: 20px;
    scale: 0.8;
}

.triangleRotate {
    transform: rotate(180deg);
}

.advanced_search_right_half {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.advancedSearchInput {
    margin: 10px;
    background-color: rgba(70, 87, 155, 1);
}

.advancedSearchText {
    margin: 5px 0 0 5px;
    color: rgba(70, 87, 155, 1);
    font-weight: 500;
}

input.checkboxCustom[type="checkbox"] {
    overflow: hidden;
    background-color: #ffffff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    display: inline-block;
    place-content: center;
}

input.checkboxCustom--active {
    background-color: rgba(70, 87, 155, 1);
}

.searchCheckbox {
    min-width: 25px;
    min-height: 25px;
    width: 25px;
    background-color: grey;
    margin: 5px 5px 5px 5px;
    vertical-align: center;
}

.nextEachOther {
    display: flex;
}

.flexdirectionRow {
    display: flex;
    flex-direction: row;
}

.advancedSearchOuter {
    width: 87%;
    height: 35px;
    border-radius: 10px;
    border-color: rgba(0, 0, 0, 0.1);
    background-color: rgba(70, 87, 155, 0.2);
    margin: 20px;
    padding: 8px;

}

.advanced_search_half {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.moreSearchOptionsField{
    background: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
    border-radius: 15px;
}

.inputSelectCustom {
    background-color: rgba(0, 0, 0, 0.1);
}

.inputSelectCustomPrefix__value-container {
    min-height: 50px;
}

.advanced_search_field2 {
    width: 100%;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.1);
    margin: 8px;
    padding: 5px;
    display: flex;
    align-items: center;
}

.hide{
    display: none;
}

.csvUpload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 24vw;
    height: 200px;
    cursor: pointer;
    background-color: #46579B;
    padding: 10px;
    margin: 10px 0;
    border-radius: 10px;
    color: white;
    overflow: hidden;
}

.uploadFull {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50vw;
}

.centerFullUpload {
    display: flex;
    justify-content: center;
}

.outerUpload {
    display: flex;
    justify-content: center;
    width: 100%;
}

.outerFilesUpload {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.uploadHalf {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uploadCraft {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 5px;
    display: flex;
    align-content: center;
    height: 100px;
    background-color: #46579B;
    color: white;
}

.uploadDropdown {
    width: 55vw;
    border-radius: 10px;
    border-color: rgba(0, 0, 0, 0.1);
    margin: 5px;
    padding: 5px;
    display: flex;
    align-items: center;
    height: 50px;
    background-color: #46579B;
    color: white;
}

.uploadExtras {
    height: 30px;
    margin: 5px;
    padding: 10px 10px 10px 15px;
    border-radius: 10px;
    border: 0;
    background-color: #ffffff;
}

.uploadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 0;
    background-color: #46579B;
    color: white;
    width: 100%;
    height: 60px;
    margin: 10px;
    cursor: pointer;
    font-size: 30px;
    font-weight: bold;
}

.currentlyUploadingButton {
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(70, 87, 155, 0.5);
}

.textInput {
    border: 0;
    border-radius: 5px;
    height: 34px;
    margin: 0 5px 0 0;
    padding-left: 5px;
    width: 98%;
    font-size: 17px;
}

.filterWords {
    width: 35%;
    height: 100%;
    float: left;
    border-style: solid;
    background-color: #dddddd;
}

.filterExtras {
    width: 25%;
    height: 100%;
    float: left;
    border-style: solid;
    background-color: #dddddd;
}

.start {
    width: 35%;
    height: 100%;
    float: left;
    border-style: solid;
    background-color: #dddddd;
}

.menubarOuter {
    display: flex;
    justify-content: center;
}

.logoView {
    display: flex;
    align-items: center;
}

.logo {
    width: 200px;
    margin: 20px;
    cursor: pointer;
}

.menubar {
    color: #0000ff;
    font-size: 20px;
    margin-left: auto;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    height: 40px;
}

.menubarButtons {
    display: flex;
    justify-content: center;
    height: 38px;
    width: 140px;
    cursor: pointer;
    border-radius: 15px;
    border: 0 solid #ffffff;
    padding: 2px 0 1px 2px;
    margin: 5px;
}

.menubarTextProject {
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    padding: 0 20px 0 15px;
}

.menubarText {
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    padding: 8px 25px 8px 5px;
}

.menubarImg {
    padding: 2px 0 2px 10px;
    margin-right: auto;
}

.logoutImg {
    padding: 1px 0 2px 16px;
    margin-right: auto;
}

.menuButtonUnselected {
    color: rgba(70, 87, 155, 1);
    background-color: #ffffff;
}

.menuButtonSelected {
    background-color: rgba(70, 87, 155, 1);
    color: #ffffff;
}

.floatLeft {
    float: left;
}

.footer {
    display: flex;
    max-height: 10vh;
    background: #303030;
    color: white;
    padding: 20px 40px;
    margin-top: auto;
}

.imprint {
    margin: 150px 150px;
}

.footerButtons {
    border-radius: 8px;
    margin-right: 2em;
    height: 3em;
    width: 9em;
}

.tableAll {
    margin: 20px 0 20px 30px;
    width: 96vw;
}

.tableHeader {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

td:first-child {
    border-radius: 10px 0 0 10px;
}

td:last-child {
    border-radius: 0 10px 10px 0;
}

.tableCell {
    background-color: white;
    border: 0;
    padding: 15px;
    height: 150px;
    vertical-align: top;
}
.blueRow {
    background-color: blue;
}

.lightBlueRow {
    background-color: lightblue;
}

.projectsContainer {
    display: flex;
    justify-content: flex-start;
    width: 100vw;
    flex-wrap: wrap;
    background-color: rgba(194, 219, 218, 1);
}

.projectTitle {
    color: rgba(70, 87, 155, 1);
    font-size: 80px;
    font-weight: 500;
    text-align: center;
    margin: 0 0 40px 0;
}

.projects {
    background-color: rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 40px;
    margin: 25px;
    padding: 4px 25px 4px 25px;
    border-radius: 10px;
    min-width: 370px;
    max-width: 370px;
    min-height: 420px;
    max-height: 420px;
    overflow: auto;
}

.projectInfoInner {
    background-color: rgba(102, 193, 191, 1);
    border-radius: 10px;
    padding: 6px;
    font-size: 18px;
    margin-right: 25px;
    display: flex;
    align-items: center;
}

.projectInfo {
    display: flex;
    margin: 0 0 35px 0;
}

.projectHeader {
    color: rgba(70, 87, 155, 1);
    margin: 0 0 8px 0;
    font-weight: 500;
}

.projectCraftNames {
    background-color: rgba(70, 87, 155, 1);
    color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    padding: 3px 3px 5px 6px;
    margin: 5px 0 5px 0;
    font-size: 18px;
}

.addProjectImg {
    display: block;
    width: 40px;
    height: 40px;
    scale: 0.4;
    position: absolute;
    bottom: 230px;
    left: 310px;
}

.newProjectOuter {
    display: flex;
    position:relative;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.newProject {
    background-color: rgba(70, 87, 155, 1);
    text-align: center;
    padding: 20px;
    border-radius: 20px;
    font-size: 30px;
    width: 250px;
    cursor: pointer;
}

.lvBarOuter {
    color: rgba(70, 87, 155, 1);
    font-size: 16px;
}

.split {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.loginOuter {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 70vh;
    color: rgba(70, 87, 155, 1);
    font-size: 50px;
    font-weight: 500;
}

.loginOuter2 {
    max-width: 37vw;
    margin-right: auto;
    margin-left: auto;
}

.loginTitle {
    font-size: 70px;
}

.loginText {
    font-size: 20px;
    padding: 50px;
}

.forgotPasswordTitle {
    font-size: 60px;
}

.loginForgotPassword {
    cursor: pointer;
    color: #46579B;
    font-size: 17px;
    margin: 5px 0 40px 0;
    font-weight: 600;
}

.login_email_saved {
    font-size: 15px;
    color: green;
}

.login_email_not_saved {
    font-size: 15px;
    color: red;
}

.loginExtras {
    width: 370px;
    height: 30px;
    margin: 5px;
    padding: 15px 25px 15px 25px;
    border-radius: 10px;
    border: 0;
    background-color: #46579B;
    color: white;
    font-size: 18px;
}

.forgotPassword {
    width: 20vw;
    height: 60px;
    margin: 5px;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    border: 0;
    background-color: #101010;
    color: white;
}

.loginButton {
    padding-bottom: 25px;
}

.loginBtn {
    width: 420px;
    height: 60px;
    margin: 5px;
    padding: 20px 25px 33px 25px;
    border-radius: 10px;
    border: 0;
    background-color: #66C1BF;
    color: #46579B;
    font-weight: 550;
    font-size: 18px;
    box-shadow: 2px 7px 8px -1px rgba(0,0,0,0.29);
    cursor: pointer;
}

.registerBtn {
    cursor: pointer;
    color: #46579B;
    font-size: 17px;
    margin: 0 0 40px 0;
    font-weight: 600;
}

.wrongLogin {
    font-size: 15px;
    color: red;
}

.outerInputSelectUpload {
    display:  flex;
    flex-direction: column;
}

.inputSelect {
    min-width: 400px;
    width: 400px;
    margin: 10px 10px 10px 0;
}

.uploadInputSelect {
    flex-grow: 1;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.searchRotate {
    animation: rotation 2s linear infinite;
}

.searchRotatePosition{
    display: flex;
    justify-content: center;

}

.searchFeedbackSize {
    width: 40px;
}

.searchTableHeader {
    font-size: 17px;
    align-items: center;
}

.hintPosition {
    display: none;
    position: relative;
    z-index: 100;
}

.hint {
    position: absolute;
    width: 150px;
    color: white;
    text-align: center;
    background-color: #46579B;
    /*background-color: black;*/
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
}

.hintBigger {
    position: absolute;
    width: 250px;
    color: white;
    /*text-align: center;*/
    background-color: #46579B;
    /*background-color: black;*/
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
}